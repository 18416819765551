* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

p,
span {
  font-size: 1.6rem;
}

ul {
  list-style: none;
  border-radius: 5px;
  padding: 10px;
}
li {
  font-size: 1.6rem;
  padding: 10px;
  background: rgba(0, 0, 0, 0.02);
}
li:nth-of-type(even) {
  background: #eee;
}

h1 {
  font-size: 4rem;
}

button {
  background: #eee;
  border-radius: 4px;
  border: none;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
}
button img {
  border-radius: 50%;
  margin-left: 5px;
}
button span {
  vertical-align: middle;
}

[data-recording='true'] {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgb(218, 18, 18);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(40, 167, 69, 0);
  }
}
